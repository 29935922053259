import React, { useEffect, useState } from "react";
// import hljs from 'highlight.js';
// import '../static/css/dracula.css'
import parse from 'html-react-parser';


const Tab = ({ children, active = 0 }) => {
  const [activeTab, setActiveTab] = useState(active);
  const [tabsData, setTabsData] = useState([]);

  useEffect(() => {
    let data = [];

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;

      const {
        props: { tab, children },
      } = element;
      data.push({ tab, children });
    });

    setTabsData(data);
  }, [children]);

//   useEffect(() => {
//     hljs.highlightAll();
//   }, []);

  return (
    <div className="w-100 custom-tab">
<ul className="flex overflow-scroll ">
        {tabsData.map(({ tab }, idx) => (
          <li className="inline-flex p-2 justify-center items-center ">
            <a
              className={` px-2 rounded-lg  ${idx === activeTab ? "active bg-[#052b73]" : "text-gray-400"}`}
              href="javascript:void(0)"
              onClick={() => setActiveTab(idx)}
            >
              {tab}
            </a>
          </li>
        ))}
      </ul>

      <div className="tab-content px-1 sm:px-3 py-6 leading-9 h-[320px] overflow-scroll " >
    {tabsData[activeTab] && parse(tabsData[activeTab].children)}
      </div>
    </div>
  );
};

const TabPane = ({ children }) => {
  return { children };
};

Tab.TabPane = TabPane;

export default Tab;