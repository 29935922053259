import React from 'react'
import {useEffect, useState} from 'react';
// import HomepageHeroImage2 from '../static/images/assets/Hero-home.png';
import HomepageHeroImage from '../static/images/assets/HomepageHero5.png';
import heroScar from '../static/images/assets/heroScar.jpg';

const HomepageHero = () => {

  return (
    <div className=''>
    {/* <!-- Hero --> */}
<div className="max-w-[85rem] mx-auto sm:px-6  px-4 lg:px-8 ">
  {/* <!-- Grid --> */}
  {/* <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center bg-gradient-to-r from-[#E4F2FE] to-[#eff7fa] rounded-2x px-8 sm:px-0l bg-[url('')]"> */}
  <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-4 md:items-center bg-[#E4F2FE]  rounded-2xl px-8 sm:px-0l ">
  {/* <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center  rounded-3xl px-8 sm:px-0l bg-[#5a50fe]" style={{
        backgroundImage: `url(${heroScar})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        // height: '500px',
      }}> */}
    <div className='mt-10 md:mt-0 text-center sm:text-left sm:px-10'>


<div> 
      <h1 className="block text-4xl font-black text-[#040051] lg:text-5xl lg:leading-tight dark:text-white mb-6 sm:mb-0 wow animate__animated animate__fadeInUp " data-wow-delay=".2s" > The Pan-African Messaging  <span className="text-[#040051]">Platform</span></h1>

      </div>
      <p className="mt-3 text-md sm:text-base text-[#4A487A] dark:text-gray-400 wow animate__animated animate__fadeInUp" data-wow-delay=".4s"> Celenity provides an API-first messaging platform for businesses to send bulk and personalised messages over SMS, Voice, Whatsapp Business, USSD etc. across  Africa
</p>


      {/* <!-- Buttons --> */}
      <div className="mt-7 grid gap-3 w-full sm:inline-flex">
        <a className="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-800 hover:text-white border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800 wow animate__animated animate__fadeInUp" data-wow-delay=".6s" href="#">
          Get started for free
          <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </a>
        <a className="lg:ml-6 sm:ml-0inline-flex justify-center items-center gap-x-3.5 text-sm lg:text-base text-center bg-black/[.1] hover:bg-black/[.2] shadow-sm font-medium rounded-md focus:outline-none focus:ring-2  transition py-3 px-4 dark:border-gray-800 dark:hover:border-gray-600 dark:shadow-slate-700/[.7] dark:text-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800 text-[#221f5b] wow animate__animated animate__fadeInUp" data-wow-delay=".8s" href="#">
          Contact sales team
        </a>
      </div>
      {/* <!-- End Buttons --> */}


    </div>
    {/* <!-- End Col --> */}

    <div className="relative ml-4">
      <img className=" rounded-md animate__animated wow animate__fadeIn animate__delay-.46s" data-wow-delay="0.6s" src={HomepageHeroImage} alt="Celenity Hero Image"/>
      {/* <div className="absolute inset-0 -z-[1] bg-gradient-to-tr from-gray-200 via-white/0 to-white/0 w-full h-full rounded-md mt-4 -mb-4 mr-4 -ml-4 lg:mt-6 lg:-mb-6 lg:mr-6 lg:-ml-6 dark:from-slate-800 dark:via-slate-900/0 dark:to-slate-900/0"></div> */}

      {/* <!-- SVG--> */}
      <div className="absolute bottom-0 left-0">
 
      </div>
      {/* <!-- End SVG--> */}
    </div>
    {/* <!-- End Col --> */}
  </div>
  {/* <!-- End Grid --> */}
</div>
{/* <!-- End Hero --> */}
    </div>
  )
}

export default HomepageHero