import React from 'react'
import SectionHeader from './SectionHeader'

function Products2() {
  return (
    <div className='max-w-[85rem] px-4 py-20 sm:px-6 lg:px-8 lg:py-14 mx-auto'>
             <SectionHeader
              firstLine="Get your message across over"
              secondLine="every possible"
              thirdLine="Channel"
              subtitle="Create your conversational ecosystem with multiple integration options"
            />


        <div className='max-w-[85rem] lg:max-w-[75rem]   sm:px-6 lg:px-8 lg:pt-10 mx-auto'>
        <div className='flex flex-wrap lg:flex-nowrap gap-y-10 gap-x-16 mb-10'>
            <div className='bg-[#050422] rounded-2xl lg:rounded-3xl p-6 lg:p-10 wow animate__animated animate__bounceInUp' data-wow-delay=".2s">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0.01 56 55.99" className='w-8 h-8 lg:w-12 lg:h-12 mb-4 lg:mb-6'>
<path d="M50.75 14.0059H49V27.7652C49 33.6824 44.2312 38.4949 38.3687 38.4949H14V43.7449C14 46.6434 16.3516 48.9949 19.25 48.9949H44.8875L53.1562 55.6121C53.4734 55.8637 53.8672 55.9949 54.25 55.9949C54.5016 55.9949 54.7641 55.9402 55.0047 55.8199C55.6062 55.5246 56 54.9121 56 54.2449V19.2559C56 16.3574 53.6484 14.0059 50.75 14.0059Z" fill="#D1CEFE"/>
<path d="M38.5 0.00585938H7C3.13906 0.00585938 0 3.14492 0 7.00586V42.0059C0 42.684 0.39375 43.2965 1.00625 43.5918C1.23594 43.7012 1.49844 43.7559 1.75 43.7559C2.15469 43.7559 2.54844 43.6246 2.86563 43.3512L12.8844 35.0059H38.5C42.3609 35.0059 45.5 31.8668 45.5 28.0059V7.00586C45.5 3.14492 42.3609 0.00585938 38.5 0.00585938Z" fill="#5A50FE"/>
<path d="M33.25 10.5059H12.25C11.2875 10.5059 10.5 11.2934 10.5 12.2559C10.5 13.2184 11.2875 14.0059 12.25 14.0059H33.25C34.2125 14.0059 35 13.2184 35 12.2559C35 11.2934 34.2125 10.5059 33.25 10.5059Z" fill="#FAFAFA"/>
<path d="M26.25 17.5059H12.25C11.2875 17.5059 10.5 18.2934 10.5 19.2559C10.5 20.2184 11.2875 21.0059 12.25 21.0059H26.25C27.2125 21.0059 28 20.2184 28 19.2559C28 18.2934 27.2125 17.5059 26.25 17.5059Z" fill="#FAFAFA"/>
</svg>

<h4 className='text-white font-bold text-lg lg:text-2xl'>SMS Messaging
</h4>
<p className='text-white leading-6 mt-2 text-base lg:text-lg'>
Send Personalized Bulk SMS to your customers at the speed of light. Send one-time passwords, PIN codes, notifications and verifications to customers securely and at scale.

</p>
            </div>
            <div className='bg-[#f3f3f3] rounded-2xl lg:rounded-3xl p-6 lg:p-10 wow animate__animated animate__bounceInUp' data-wow-delay=".2s">

 <svg
                    xmlns="http://www.w3.org/2000/svg"
           
                    fill="none"
                    className='mb-4
             lg:mb-6'
             viewBox="0 0 400 50"

                  >
                    <rect width="48" height="48" fill="#fff" rx="8" />

                    <path
                      fill="#8484FF"
                      d="M22.586 22.586a2 2 0 0 1 2.046-.483l14.85 4.95a2 2 0 0 1 0 3.794l-6.476 2.159-2.159 6.476a2 2 0 0 1-3.795 0l-4.95-14.85a2 2 0 0 1 .484-2.046z"
                    />
                    <path
                      fill="#DDF"
                      fill-rule="evenodd"
                      d="M35.772 21.66c.076.386.343.712.717.836.702.234 1.413-.322 1.279-1.05C36.566 14.933 30.86 10 24 10c-7.732 0-14 6.268-14 14 0 6.81 4.861 12.483 11.302 13.74.727.142 1.29-.562 1.064-1.267a1.096 1.096 0 0 0-.829-.726C16.091 34.611 12 29.783 12 24c0-6.627 5.373-12 12-12 5.827 0 10.683 4.153 11.772 9.66zm-7.767-2.128c.15.135.322.244.514.308 1.077.359 1.946-.687 1.144-1.491a8 8 0 0 0-11.367 11.26c.794.808 1.848-.049 1.501-1.129a1.473 1.473 0 0 0-.303-.518 6 6 0 0 1 8.51-8.43z"
                      clip-rule="evenodd"
                    />
                  </svg>

<h4 className='text-[#050422] font-bold text-lg lg:text-2xl'>OTP Verify
</h4>
<p className='text-[#4a487a]  lg:leading-6 mt-2 text-sm lg:text-lg'>
Easily validate customers, prevent impersonations and reduce fraud by sending secure one-time passwords, PIN codes and verifications to customers securely and at scale.

</p>
            </div>
  
        </div>
        <div className='flex flex-col-reverse lg:flex-row  flex-wrap lg:flex-nowrap gap-y-10 gap-x-16 mb-10'>
            <div className='bg-[#f3f3f3] rounded-2xl lg:rounded-3xl p-6 lg:p-10 wow animate__animated animate__bounceInUp' data-wow-delay=".2s">

            <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // viewBox="0 0.01 56 55.99"
                    viewBox="0 0 40 50"
                    width="48"
                    height="48"
                    fill="none"
                    className='w-8 h-8 lg:w-12 lg:h-12 mb-4
             lg:mb-6'
             
                  >
                    <rect width="48" height="48" fill="#fff" rx="8" />
                    <path
                      fill="#9B9797"
                      d="M10 14a4 4 0 0 1 8 0 4 4 0 0 1-8 0zm10 0a4 4 0 0 1 8 0 4 4 0 0 1-8 0zm14-4a4 4 0 0 1 0 8 4 4 0 0 1 0-8z"
                    />
                    <path
                      fill="#E2DEDF"
                      d="M38 34a4 4 0 0 1-8 0 4 4 0 0 1 8 0z"
                    />
                    <path
                      fill="#9B9797"
                      d="M38 24a4 4 0 0 1-8 0 4 4 0 0 1 8 0zM28 34a4 4 0 0 1-8 0 4 4 0 0 1 8 0zm0-10a4 4 0 0 1-8 0 4 4 0 0 1 8 0z"
                    />
                    <path
                      fill="#E2DEDF"
                      d="M14 38a4 4 0 0 1 0-8 4 4 0 0 1 0 8z"
                    />
                    <path
                      fill="#9B9797"
                      d="M14 28a4 4 0 0 1 0-8 4 4 0 0 1 0 8z"
                    />
                  </svg>

<h4 className='text-[#050422] font-bold text-lg lg:text-2xl'>USSD
</h4>
<p className='text-[#4a487a]  lg:leading-6 mt-2 text-sm lg:text-lg'>Establish 2-way communication with your customers. Instantly access info, make transactions, and simplify your digital experience. Stay connected, hassle-free.

</p>
            </div>
            <div className='bg-[#5a50fe] rounded-2xl lg:rounded-3xl p-6 lg:p-10 wow animate__animated animate__bounceInUp' data-wow-delay=".2s">
          

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    // viewBox="0 10 6 55"
                    viewBox="10 8 350 35"

                    fill="none"
                    className=' mb-4 '
                  >
                                      {/* <rect width="48" height="48" fill="#fff" rx="8" /> */}

                    <path
                      fill="#fff"
                      d="M39 14.4c0 2.982-2.44 5.4-5.452 5.4-3.01 0-5.452-2.418-5.452-5.4S30.537 9 33.548 9 39 11.418 39 14.4z"
                    />
                    <path
                      fill="#fff"
                      fill-rule="evenodd"
                      d="M34.067 21.582v9.275c0 2.13-1.743 3.857-3.894 3.857h-12.98c-2.151 0-3.895-1.727-3.895-3.857V18c0-2.13 1.744-3.857 3.894-3.857h9.091c.016-.44.072-.87.165-1.286h-9.256C14.325 12.857 12 15.16 12 18v12.857C12 33.697 14.325 36 17.192 36h12.981c2.868 0 5.192-2.303 5.192-5.143V21.37c-.42.108-.854.18-1.298.212z"
                      clip-rule="evenodd"
                    />
                  </svg>

<h4 className='text-white font-bold text-lg lg:text-2xl'>Airtime & Data
</h4>
<p className='text-white leading-6 mt-2 text-base lg:text-lg'>
Send airtime and data to any phone effortlessly. Stay connected anytime, anywhere. Instantly recharge and enjoy uninterrupted communication with friends and family.
</p>
  </div>
        </div>
        <div className='flex flex-wrap lg:flex-nowrap gap-y-10 gap-x-16  '>
            <div className='bg-[#5a50fe] rounded-2xl lg:rounded-3xl p-6 lg:p-10 wow animate__animated animate__bounceInUp flex-grow-0' data-wow-delay=".2s">
            <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="none"
                    viewBox="0 0 40 50"
className='mb-4'
                  >
                                 {/* <rect width="48" height="48" fill="#fff" rx="8" /> */}

                    <rect
                      width="8"
                      height="4"
                      x="26"
                      y="15"
                      fill="#fff"
                      rx="2"
                    />
                    <rect
                      width="7"
                      height="4"
                      x="14"
                      y="29"
                      fill="#fff"
                      rx="2"
                    />
                    <path
                      fill="#fff"
                      d="M32 12.868a1 1 0 0 1 1.555-.832l6.197 4.132a1 1 0 0 1 0 1.664l-6.197 4.131A1 1 0 0 1 32 21.131v-8.263zM16 35.132a1 1 0 0 1-1.555.832l-6.197-4.132a1 1 0 0 1 0-1.664l6.197-4.131a1 1 0 0 1 1.555.832v8.263z"
                    />
                    <path
                      fill="#fff"
                      fill-rule="evenodd"
                      d="M37.154 29.277c-.746-.597-1.544-1.133-2.33-1.68-1.566-1.093-2.998-1.178-4.167.58-.656.987-1.575 1.03-2.535.598-2.648-1.194-4.693-3.032-5.891-5.707-.53-1.183-.523-2.244.717-3.08.656-.443 1.317-.967 1.265-1.934-.07-1.261-3.15-5.475-4.367-5.92-.504-.184-1.005-.172-1.518-.001-2.853.953-4.035 3.285-2.903 6.046 3.377 8.236 9.319 13.97 17.498 17.47.466.2.984.28 1.247.35 1.862.02 4.043-1.763 4.673-3.533.607-1.702-.675-2.378-1.689-3.189z"
                      clip-rule="evenodd"
                    />
                  </svg>
<h4 className='text-white font-bold text-lg lg:text-2xl'>Voice Messaging
</h4>
<p className='text-white leading-6 mt-2 text-base lg:text-lg'>
Deliver high-quality voice experiences through the low latency, carrier-grade Celenity network. Engage with as many people as you want in any local langauge.
</p>
            </div>
            <div className='bg-[#f3f3f3] rounded-2xl lg:rounded-3xl p-6 lg:p-10 wow animate__animated animate__bounceInUp flex-grow-0 ' data-wow-delay=".2s">


                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="none"
                    className='mb-4'
                    viewBox="4 0 40 50"

                  >
                    <rect width="48" height="48" fill="#fff" rx="8" />

                    <path
                      stroke="#DDF"
                      stroke-width="4"
                      d="M24 18v6h-3c-1.864 0-2.796 0-3.53.305a4 4 0 0 0-2.165 2.164C15 27.204 15 28.136 15 30m9-12v6h3c1.864 0 2.796 0 3.53.305a4 4 0 0 1 2.166 2.164C33 27.204 33 28.136 33 30"
                    />
                    <rect
                      width="8"
                      height="8"
                      x="20"
                      y="10"
                      fill="#8484FF"
                      rx="2"
                    />
                    <rect
                      width="8"
                      height="8"
                      x="11"
                      y="30"
                      fill="#8484FF"
                      rx="2"
                    />
                    <rect
                      width="8"
                      height="8"
                      x="29"
                      y="30"
                      fill="#8484FF"
                      rx="2"
                    />
                  </svg>

<h4 className='text-[#050422] font-bold text-lg lg:text-2xl'>Developer APIs
</h4>
<p className='text-[#4a487a]  lg:leading-6 mt-2 text-sm lg:text-lg'>Built by developers for devlopers, our Apis and open-source SDKs with excellent documentation are designed to get you up and running fast with a few lines of code. 


</p>
            </div>
  
        </div>
        </div>
    </div>
  )
}

export default Products2