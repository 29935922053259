export default function Accordion(props) {
    return (
        <div className="wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
      {/* {console.log(props)} */}
            <button
                className={` ${props.index != 1 ? 'mt-8':''} text-left py-3 px-8  active w-full  ${props.isOpen ? 
                                 'bg-white rounded-t-xl' : 'bg-[#ececea] rounded-xl'}  `} id="tabs-with-card-item-1" data-hs-tab="#tabs-with-card-1" aria-controls="tabs-with-card-1"
                onClick={props.toggleAccordion}
            >
             <span class="flex">
            
              <span class="grow">
                <span class="block text-3xl   text-gray-900  dark:text-gray-200">
                {props.title}
                </span>
              </span>

<svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 11.994c0-.292-.171-.68-.346-.868L15.807.389c-.46-.488-1.213-.535-1.75-.04-.479.443-.481 1.296-.038 1.777l7.904 8.605H1.231c-.68 0-1.231.566-1.231 1.264 0 .697.551 1.263 1.23 1.263h20.693l-7.904 8.605c-.443.48-.42 1.332.038 1.796.486.49 1.298.436 1.75-.06l9.847-10.736c.29-.291.341-.572.346-.869z" fill="#2C3F50"/>
</svg>

            </span>
            </button>
            {props.isOpen && (
                <div className=" p-8 text-base  bg-white box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05) shadow-[0_2px_20px_0px_rgba(0,0,0,0.05)] leading-6 lg:leading-8 ">
                   <span className="text-[#2c3f50]">{props.data}  </span> 
                </div>
            )}


        </div>
    );
};