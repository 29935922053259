import React from 'react'
import { useEffect, useState } from 'react'
import { classNames } from '../utilities/classNames';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        if (window.scrollY > 200) {
          // console.log(window.scrollY);
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      }
    
      const scrollToTop = (e) => {
        e.preventDefault();
        console.log('clicked')
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    
      useEffect(() => {
        window.addEventListener('scroll', toggleVisibility)
    
        return () => {
          window.removeEventListener('scroll', toggleVisibility)
        }
      }, [])

   // var toTopButton = document.getElementById("to-top-button");

        // When the user scrolls down 200px from the top of the document, show the button
        // window.onscroll = function () {
        //     if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        //         toTopButton.classList.remove("hidden");
        //     } else {
        //         toTopButton.classList.add("hidden");
        //     }
        // }

        // When the user clicks on the button, scroll to the top of the document
        // function goToTop() {
        //     window.scrollTo({ top: 0, behavior: 'smooth' });
        // }


      
  return (
    <div>
         <div className="fixed bottom-2 right-2 z-50">
      {/* <!-- Implement the Back Top Top Button --> */}
    <button id="to-top-button" onClick={scrollToTop} title="Scroll to top"
        class={`fixed z-90 bottom-16 transition-all ease-linear duration-150 right-8 border-0 w-12 h-12 rounded-full drop-shadow-md bg-blue-600  text-white text-xl font-bold ${isVisible ? 'translate-y-0 ' : 'translate-y-32'}`}>&uarr;</button>
        {/* {console.log(isVisible)} */}
    </div>
    </div>
  )
}

export default ScrollToTop