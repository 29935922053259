import React from 'react'
import logo from '../static/images/logo/logo.svg';
import CallToAction from './CallToAction';

const Footer = () => {
  const thisYear =  new Date().getFullYear();

  return (
<>
<CallToAction/>
<footer className="bg-[#f8faff]">
  <div className="max-w-[85rem] py-10 px-8 sm:px-6 lg:px-8 lg:pt-28 lg-pb-20 mx-auto">
    {/* <!-- Grid --> */}
    <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6">
      <div className="col-span-full lg:col-span-2">
        <a className="flex-none text-xl font-semibold text-white" href="#" aria-label="Celenity">
        <img className="h-10" src={logo} alt="" />        </a>
        <p className='text-gray-800 text-sm mt-6 sm:max-w-[60%]'> Celenity is on a mission of communication inclusion.</p>
      </div>
      {/* <!-- End Col --> */}

      <div className="col-span-1">
        <h4 className="font-semibold text-gray-900">Products</h4> 

        <div className="mt-3 grid space-y-3">
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">SMS Messaging</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">USSD</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Voice</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Airtime & Data</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Verify</a></p>
        </div>
      </div>
      {/* <!-- End Col --> */}

      <div className="col-span-1">
        <h4 className="font-semibold text-gray-900">Company</h4>
        <div className="mt-3 grid space-y-3">
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">About Us</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Pricing</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Certificates</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">News & Press</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Contact Us</a></p>
        </div>
      </div>
      {/* <!-- End Col --> */}

      <div className="col-span-1">
      <h4 className="font-semibold text-gray-900">Resources</h4>
        <div className="mt-3 grid space-y-3">
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Help Center</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Customer Stories</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Blog</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">FAQs</a></p>
          <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 text-sm" href="#">Tutorials</a></p>
        </div>
 
      </div>
      {/* <!-- End Col --> */}
    </div>
    {/* <!-- End Grid --> */}

    {/* <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center "> */}
    <div className="mt-5 sm:mt-12 grid grid-cols-5">

      <div className="flex justify-between items-center col-span-full sm:col-span-2 ">
        <p className="text-sm text-gray-600"> &copy; {thisYear} CELENITY. All rights reserved.</p>
      </div>
      {/* <!-- End Col --> */}

      <div class="space-x-6  text-sm col-span-full sm:col-span-2 mt-4 sm:mt-2">
          <a class="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 dark:text-gray-600 dark:hover:text-gray-900" href="#">Terms of Service</a>
          <a class="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 dark:text-gray-600 dark:hover:text-gray-900" href="#">Privacy</a>
          <a class="inline-flex gap-x-2 text-gray-600 hover:text-gray-900 dark:text-gray-600 dark:hover:text-gray-900" href="#">System Status</a>
        </div>

      {/* <!-- Social Brands --> */}
      <div className='col-span-full sm:col-span-1 mt-4 sm:mt-0'>
        <a className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-600 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition" href="https://facebook.com/celenityhq">
          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
          </svg>
        </a>

        <a className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-600 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition" href="#">
          <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
          </svg>
        </a>
        <a className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-600 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition" href="#">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 50 50">
    <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
</svg>
        </a>
        <a className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-100 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-gray-100 transition" href="#">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24">
    <path d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"></path>
</svg>
        </a>

      </div>
      {/* <!-- End Social Brands --> */}
    </div>
  </div>
</footer>
</>

  )
}

export default Footer