import HomepageHero from "./components/HomepageHero";
import CallToAction from "./components/CallToAction";
import ForDevelopers from "./components/ForDevelopers";
import Navbar from "./components/Navbar";
import Clients from "./components/Clients";
import Footer from "./components/Footer";
import Announcement from "./components/Announcement";
import Products from "./components/Products";
import Statistics from "./components/Statistics";
import {Helmet} from "react-helmet";
import Features from "./components/Features";
import Api from "./components/Api";
import Testimonials from "./components/Testimonials";
import ScrollToTop from "./components/ScrollToTop";
import Industry from "./components/Industry";
import Developers from "./components/Developers";
import Products2 from "./components/Products2";





function App() {

  return (
    <div>
        <Helmet>
                <title>CELENITY - The Pan African Messaging Platform</title>
                <link rel="canonical" href="https://celenity.net" />
                {/* <!-- Required Meta Tags Always Come First --> */}
  <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
  <link rel="canonical" href="https://celenity.net/"/>
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
  <meta name="description" content="Celenity provides an API-first messaging platform for businesses to send bulk and personalised messages over SMS, Voice, Whatsapp Business, USSD etc. across Africa."/>

  <meta name="twitter:site" content="@CelenityHQ"/>
  <meta name="twitter:creator" content="@CelenityHQ"/>
  <meta name="twitter:card" content="summary_large_image"/>
  <meta name="twitter:title" content="Celenity - The Pan African Messaging Platform"/>
  <meta name="twitter:description" content="Celenity provides an API-first messaging platform for businesses to send bulk and personalised messages over SMS, Voice, Whatsapp Business, USSD etc. across Africa."/>
  <meta name="twitter:image" content="https://celenity.net/static/images/celenity-og.png"/>

  <meta property="og:url" content="https://celenity.net/"/>
  <meta property="og:locale" content="en_US"/>
  <meta property="og:type" content="website"/>
  <meta property="og:site_name" content="Celenity"/>
  <meta property="og:title" content="Celenity - The Pan African Messaging Platform"/>
  <meta property="og:description" content="Celenity provides an API-first messaging platform for businesses to send bulk and personalised messages over SMS, Voice, Whatsapp Business, USSD etc. across Africa."/>
  <meta property="og:image" content="https://celenity.net/static/images/celenity-og.png"/>
  <title>The Pan-African Messaging Platform | Celenity</title>
            </Helmet>

    <Announcement/>
     <Navbar/>
     <HomepageHero/>
    <Clients titleColor="text-gray-600" logoColor="text-gray-500" />
     <Products2/>
     {/* <Products/> */}
     <Features/>
   
       {/* <Api/> */}
    <Developers/>
    
     {/* <Statistics/> */}
    
     {/* <ForDevelopers/> */}
     <Industry/>
     <Testimonials/>
   

     <Footer/>
     <ScrollToTop/>
    </div>
  );
}

export default App;
