import React from 'react'
import parse from 'html-react-parser';

const SectionHeader = (props) => {
  return (
    <div class="max-w-[85rem] px-2 py-6 sm:px-6 lg:px-8  mx-auto ">
         <h1 className="text-3xl font-black text-[#121c2d]  lg:text-5xl dark:text-white lg:leading-[3.3rem] ">
       {props.firstLine} <br/> {props.secondLine} <span className="underline decoration-gray-200 underline-offset-8"> {props.thirdLine} </span></h1>   
        <p className="mt-4 text-gray-600 xl:mt-6 dark:text-gray-300 text-xl sm:w-[700px]" >
           {parse(props.subtitle)}
        </p>
        
    </div>
  )
}

export default SectionHeader