import React from 'react'
import SectionHeader from './SectionHeader'
import featureImage1 from '../static/images/assets/developer-guy.png';
import featureImage2 from '../static/images/assets/developer-girl.png';


const Features = () => {
  return (
    <section className="py-28 wow animate-fade-up animated">
            <div className="max-w-screen-xl mx-auto px-4  md:px-8">
            <SectionHeader firstLine="Powering growth for  amazing" secondLine="" thirdLine="businesses" subtitle="Thousand of fast growing businesses use Celenity to verify and engage  their customers daily." />



{/**  Features */}
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  {/**  Grid */}
  <div class="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
    <div>
      <img class="rounded-xl w-[651px] wow animate__animated animate__bounceInUp" data-wow-delay=".2s" src={featureImage1} alt="Image Description"/>
    </div>
    {/**  End Col */}

    <div class="mt-5 sm:mt-10 lg:mt-0">
      <div class="space-y-6 sm:space-y-8">
        {/**  Title */}
        <div class="space-y-2 md:space-y-4">
          <h2 class="font-bold text-3xl lg:text-4xl text-gray-800 dark:text-gray-200 wow animate__animated animate__fadeInUp " data-wow-delay=".2s" >
          All of your customer engagement tools in one place          </h2>
          <p class="text-gray-800 wow animate__animated animate__fadeInUp " data-wow-delay=".4s" >

          Build the experiences your customers want with our flexible API stack. Integrate their favorite channels with your business workflow to elevate their customer journey.       </p>

          <p class="text-gray-800 wow animate__animated animate__fadeInUp " data-wow-delay=".6s" >Verify, activate, and connect with global customers over Bulk SMS, voice, ussd, and WhatsApp.</p>
        </div>
        {/**  End Title */}
<div>
<a className="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-800 hover:text-white border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4 dark:focus:ring-offset-gray-800 wow animate__animated animate__fadeInUp" data-wow-delay=".6s" href="#">
          Get started for free
          <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </a></div>

      </div>
    </div>
    {/**  End Col */}
  </div>
  {/**  End Grid */}
</div>
{/**  End Features */}


{/**  Features */}
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  {/**  Grid */}
  <div class="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
  

    <div class="mt-5 sm:mt-10 lg:mt-0">
      <div class="space-y-6 sm:space-y-8">
        {/**  Title */}
        <div class="space-y-2 md:space-y-4">
          <h2 class="font-bold text-3xl lg:text-4xl text-gray-800 dark:text-gray-200 wow animate__animated animate__fadeInUp " data-wow-delay=".2s" >
Get Actionable Insights and Reporting          </h2>
          <p class="text-gray-800 wow animate__animated animate__fadeInUp " data-wow-delay=".4s" >
          Gain valuable insights and comprehensive customer reporting with our solution. Get actionable data to make informed decisions and optimize your customer interactions.          </p>
          <p class="text-gray-800 wow animate__animated animate__fadeInUp " data-wow-delay=".6s" > Drive Revenue and Customer Retention through Celenity's cloud based messaging platform.</p>
        </div>
        {/**  End Title */}

        {/**  List */}
        <ul role="list" class="space-y-2 sm:space-y-4 wow animate__animated animate__fadeInUp " data-wow-delay=".8s" >
          <li class="flex space-x-3">
            {/**  Solid Check */}
            <svg class="flex-shrink-0 h-6 w-6 text-blue-600 dark:text-blue-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1"/>
              <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor"/>
            </svg>
            {/**  End Solid Check */}

            <span class="text-sm sm:text-base text-gray-500">
              <span class="font-bold">Real time performance and  delivery reports  </span> 
            </span>
          </li>

          <li class="flex space-x-3">
            {/**  Solid Check */}
            <svg class="flex-shrink-0 h-6 w-6 text-blue-600 dark:text-blue-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1"/>
              <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor"/>
            </svg>
            {/**  End Solid Check */}

            <span class="text-sm sm:text-base text-gray-500">
             <span class="font-bold"> Actionable insights  on customer data</span>
            </span>
          </li>

          <li class="flex space-x-3">
            {/**  Solid Check */}
            <svg class="flex-shrink-0 h-6 w-6 text-blue-600 dark:text-blue-500" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1965 7.85999C15.1965 3.71785 11.8387 0.359985 7.69653 0.359985C3.5544 0.359985 0.196533 3.71785 0.196533 7.85999C0.196533 12.0021 3.5544 15.36 7.69653 15.36C11.8387 15.36 15.1965 12.0021 15.1965 7.85999Z" fill="currentColor" fill-opacity="0.1"/>
              <path d="M10.9295 4.88618C11.1083 4.67577 11.4238 4.65019 11.6343 4.82904C11.8446 5.00788 11.8702 5.32343 11.6914 5.53383L7.44139 10.5338C7.25974 10.7475 6.93787 10.77 6.72825 10.5837L4.47825 8.5837C4.27186 8.40024 4.25327 8.0842 4.43673 7.87781C4.62019 7.67142 4.93622 7.65283 5.14261 7.83629L7.01053 9.49669L10.9295 4.88618Z" fill="currentColor"/>
            </svg>
            {/**  End Solid Check */}

            <span class="text-sm sm:text-base text-gray-500">
              User Experience Design
            </span>
          </li>
        </ul>
        {/**  End List */}
      </div>
    </div>
    {/**  End Col */}
    <div>
      <img class="rounded-xl w-[651px] wow animate__animated animate__bounceInUp" data-wow-delay=".2s" src={featureImage2} alt="Image Description"/>
    </div>
    {/**  End Col */}
  </div>
  {/**  End Grid */}
</div>
{/**  End Features */}




</div>
</section>

  )
}

export default Features