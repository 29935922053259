import {React, useState} from 'react';
import Accordion from './Accordion';
import SectionHeader from './SectionHeader';

const Industry = () => {
    const [activeImage, setActiveImage] = useState("https://images.unsplash.com/photo-1605629921711-2f6b00c6bbf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=900&q=80");
    const [accordions, setAccordion] = useState([
        {
            key: 1,
            title: 'Retail',
            data: `Send targeted messages to your customers with special offers, coupons, and reminders. SMS boasts a 98% open rate, ensuring that your messages will be read. Our platform is designed with retailers in mind, enjoy features like automated SMS, Rich-content and real-time analytics.`,
            isOpen: true,
            img: "https://images.unsplash.com/photo-1605629921711-2f6b00c6bbf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=920&q=80"
        },
        {
            key: 2,
            title: 'Ecommerce',
            data: `Boost sales and engagement for your e-commerce business. Send transactional messages, discounts, and recommend products to keep your customers engaged. Real-time tracking analytics lets you see how customers behave and optimizes your marketing campaigns for better results.`,
            isOpen: false,
            img: "https://images.unsplash.com/photo-1665686306574-1ace09918530?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=920&q=80"
        },
        {
            key: 3,
            title: 'Finance',
            data: `When it comes to the finance industry, trust and reliability are key. SMS from trusted brands has a proven ROI. With features like 2-factor authentication and fraud alerts, SMS can help boost security and protect your customers' sensitive information. Trust us to help you grow your finance business.`,
            isOpen: false,
            img: "https://images.unsplash.com/photo-1598929213452-52d72f63e307?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=920&q=80"
        },
        {
            key: 4,
            title: 'Health Care',
            data: `We understand the unique needs of healthcare institutions and have tailored our services to meet your specific requirements. Send appointment reminders, health tips, emergency alerts, patient surveys, follow-up care reminders, health campaigns, bills and payment remiders etc.`,
            isOpen: false,
            img: "https://images.unsplash.com/photo-1605629921711-2f6b00c6bbf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=920&q=80"
        },
 
   
 
    ]);



    const toggleAccordion = (accordionkey) => {
        const updatedAccordions = accordions.map((accord) => {
            if (accord.key === accordionkey) {

                setActiveImage( accord.img);
                // console.log('the image ', activeImage);
                return { ...accord, isOpen: !accord.isOpen };
            } else {
                return { ...accord, isOpen: false };
            }
        });
  
        setAccordion(updatedAccordions);
    };
  


  return (
    <div className='bg-[#f3f9ff]'>
        {/* <!-- Features --> */}
<div class="max-w-[85rem] px-8 sm:px-6 pt-20 lg:py-28 mx-auto">

<SectionHeader firstLine="Trusted by over 45K+ businesses, " secondLine="across" thirdLine="industries" subtitle="Celenity is a growth engine for a new generation of innovative, forward-looking organizations operating in Africa" />
  <div class="relative p-0 sm:p-0 sm:pt-20">
    {/* <!-- Grid --> */}
    <div class="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 ">
      <div class="mb-10 lg:mb-0 lg:col-span-5">
 

        {/* <!-- Tab Navs --> */}
        <nav class="grid " aria-label="Tabs" role="tablist">

        <div className="">
                {accordions.map((accordion) => (
                    <Accordion
                        key={accordion.key}
                        index={accordion.key}
                        title={accordion.title}
                        data={accordion.data}
                        isOpen={accordion.isOpen}
                        toggleAccordion={() => toggleAccordion(accordion.key)}
                    />
                ))}
            </div>

        </nav>
        {/* <!-- End Tab Navs --> */}
      </div>
      {/* <!-- End Col --> */}

      <div class="lg:col-span-7 order-last hidden sm:block" >
        <div class="relative">
          {/* <!-- Tab Content --> */}
          <div>
            <div id="tabs-with-card-1" role="tabpanel" aria-labelledby="tabs-with-card-item-1" className=" wow animate__animated animate__fadeIn" data-wow-delay=".2s">
              <img class="shadow-xl shadow-gray-200 rounded-xl dark:shadow-gray-900/[.2]" src={activeImage} alt="Image Description"/>
            </div>

 
          </div>
          {/* <!-- End Tab Content --> */}

          {/* <!-- SVG Element --> */}
          <div class="hidden absolute top-0 right-0 translate-x-20 md:block lg:translate-x-20">
            <svg class="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
              <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
              <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" stroke-width="10" stroke-linecap="round"/>
            </svg>
          </div>
          {/* <!-- End SVG Element --> */}
        </div>
      </div>
      {/* <!-- End Col --> */}
    </div>
    {/* <!-- End Grid --> */}

    {/* <!-- Background Color --> */}
    <div class="absolute inset-0 grid grid-cols-12 w-full h-full ">
      <div class="col-span-full lg:col-span-7 lg:col-start-0 bg-gray-100 w-full  rounded-xl   h-5/6  lg:h-full dark:bg-white/[.075]"></div>
    </div>
    {/* <!-- End Background Color --> */}
  </div>
</div>
{/* <!-- End Features --> */}
    </div>
  )
}

export default Industry