import React from 'react'
import greenLine from '../static/images/assets/line-section-green.png';
import SectionHeader from './SectionHeader'

const CallToAction = () => {
  return (

    <div className='py-10 lg:py-1  bg-[#f3f9ff]'>
    <div class="container p-10 mx-auto xl:px-0 ">
        <div class="relative flex flex-wrap lg:items-center justify-between w-full max-w-6xl gap-5 mx-auto overflow-hidden text-white bg-indigo-600 bg-gradient-to-r from-indigo-600 to-indigo-700 px-7 py-7 lg:px-12 lg:py-16 lg:flex-nowrap rounded-xl">
            <div class="absolute w-24 h-24 bg-white rounded-full bg-gradient-to-b from-white to-indigo-600 opacity-20 -z-1 left-3 bottom-3"></div>
            <div class="absolute w-24 h-24 bg-white rounded-full bg-gradient-to-b from-white to-indigo-600 opacity-20 -z-1 left-1/2 -top-10"></div>
            <div class="relative z-0 flex-grow text-left">
                <h2 class="text-3xl font-medium lg:text-3xl wow animate__animated animate__fadeInUp" data-wow-delay=".2s">Ready to try out Celenity?</h2>
                <p class="mt-2 text-white text-opacity-80 lg:text-xl wow animate__animated animate__fadeInUp" data-wow-delay=".3s"> Get started for free. No credit card required.</p>
            </div>
            <div class="relative flex-shrink-0 w-full text-center lg:w-auto">
                <a href="#" target="_blank" rel="noopener" class="inline-block py-3 lg:mx-auto font-medium text-center text-indigo-600 bg-white rounded-md sm:text-lg px-7 lg:px-10 lg:py-5 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">Create your free account</a>
            </div>
        </div>
    </div>
</div>
  )
}

export default CallToAction